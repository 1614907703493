import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Table} from "react-bootstrap";
import {getLimitsTelegramUsers, updateDescriptionTelegramUser} from "../http/telegramUsersAPI";
import moment from "moment-timezone";
import {Link} from "react-router-dom";

const LimitsList = () => {
	const [entities, setEntities] = useState([]);
	const [showInfo, setShowInfo] = useState({});

	useEffect(() => {
		const dateString = moment().format('MM-YYYY');
		getLimitsTelegramUsers(dateString)
			.then((res) => {
				setShowInfo(
					res.reduce((acc, current) => {
						acc[current.id] = false;
						return acc;
					}, {})
				);
				setEntities([...res]);
			})
			.catch(alert)
	}, []);

	const saveDescriptionHandler = (id) => {
		const candidate = entities.find(el => el.id === id);
		updateDescriptionTelegramUser(candidate.id, candidate.description)
			.catch(alert);
	}

	const updateDescriptionFieldHandler = (e, id) => {
		entities.find(el => el.id === id).description = e.target.value;
		setEntities([...entities]);
	}

	const openLimitsBlock = (id) => {
		showInfo[id] = !showInfo[id];
		setShowInfo({...showInfo});
	}

	return (
		<Container className={'text-white mt-3'}>
			<Table bordered className={'mt-3 text-white'}>
				<thead>
				<tr>
					<th>Описание</th>
					<th>ID</th>
					<th>Username</th>
					<th>Имя</th>
					<th>Фамилия</th>
					<th>Сохранить</th>
				</tr>
				</thead>
				<tbody>
				{entities.map(el =>
					<React.Fragment key={el.userId}>
						<tr onClick={() => openLimitsBlock(el.id)} style={{cursor: 'pointer'}}>
							<td>
								<Form.Control
									onChange={e => updateDescriptionFieldHandler(e, el.id)}
									style={{background: "#002e4e", color: "white", marginRight: 5}}
									value={el.description}
								/>
							</td>
							<td>
								{el.userId}
							</td>
							<td>
								{el.userName &&
									<a target={"_blank"} className={'text-white'} href={`https://t.me/${el.userName}`}>@{el.userName}</a>}
							</td>
							<td>
								{el.firstName}
							</td>
							<td>
								{el.lastName && el.lastName}
							</td>
							<td>
								<Button
									onClick={() => saveDescriptionHandler(el.id)}
								>Сохранить</Button>
							</td>
						</tr>
						{
							showInfo[el.id] && <tr>
								<td colSpan="6">
									<ul>
										{el.limits.map((limit, index) => (
											<li key={index}>
												<strong>Chat ID:</strong> {limit.chatId},
												<strong> Последнее сообщение</strong> {moment(limit.lastMessageDate).tz('Asia/Jerusalem').format('YYYY.MM.DD HH:mm:ss')}
											</li>
										))}
									</ul>
									<Link to={`/messages/exchange/${el.userId}`} target={"_blank"}
												style={{textDecoration: "none", color: "white"}}>
										<Button variant={"primary"} size={"sm"}
														style={{backgroundColor: "#002e4e", borderColor: "#45486d"}}>
											<b>Показать сообщения</b>
										</Button>
									</Link>
								</td>
							</tr>
						}
					</React.Fragment>
				)}
				</tbody>
			</Table>
		</Container>
	);
};

export default LimitsList;