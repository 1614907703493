import React, {useEffect, useState} from 'react';
import {
	getAllServiceVariablesByFilter,
	updateServiceVariables
} from "../http/serviceVariableAPI";
import {Button, Form} from "react-bootstrap";
import {sendRates} from "../http/botAPI";


const VariablesSettings = ({variablesArray}) => {
	const [variables, setVariables] = useState([]);
	const [requestStatus, setRequestStatus] = useState(null);
	const [variablesInputSettings, setVariablesInputSettings] = useState({});

	useEffect(() => {
		getAllServiceVariablesByFilter(variablesArray.join(','))
			.then(data => {
				const localVariableSettings = {};
				for (const variable of data) {
					if (['googleProxies'].includes(variable.name))
						localVariableSettings[variable.name] = {
							type: 'textarea',
							rows: 10
						}
					else if (['channelsForParsingUsers'].includes(variable.name))
						localVariableSettings[variable.name] = {
							type: 'textarea',
							rows: 5
						}
					else if (['orangeReplyMessage', 'blueReplyMessage', 'exchangeRatesCaption', 'exchangeChatsMuteMessage', 'messageForGoldenUsers', 'messageForBasicUsers', 'startMessageForAPodpiska', 'admexCauseMessage', 'admexBanMessage'].includes(variable.name))
						localVariableSettings[variable.name] = {
							type: 'textarea',
							rows: 4
						}
					else {
						localVariableSettings[variable.name] = {
							type: 'input',
							rows: 1
						}
					}
				}
				setVariablesInputSettings(localVariableSettings);
				setVariables([...data]);
			})
			.catch(console.error)
	}, []);

	const changeValueVariableHandler = (e, id) => {
		const variable = variables.find(el => el.id === id);
		variable.value = e.target.value;

		setVariables([...variables]);
	}

	const saveVariablesHandler = async () => {
		updateServiceVariables(variables)
			.then(() => setRequestStatus('Сохранено'))
			.catch(err => setRequestStatus(`Ошибка: ${err.message}`));
	}

	const sendRatesHandler = async () => {
		sendRates()
			.then(() => alert('Запрос отправлен'))
			.catch((e) => alert(`Ошибка отправки запроса: ${e.message}`))
	}

	return (
		<>
			{
				variables.length &&
				<Form className={'mt-3 mb-3'}>
					{variables.map(variable => (
						<Form.Group key={variable.id} className={'mt-2'}>
							<Form.Label style={{color: 'white'}}>{variable.title}</Form.Label>
							{
								<Form.Control
									type={'text'}
									as={variablesInputSettings[variable.name].type}
									value={variable.value}
									rows={variablesInputSettings[variable.name].rows}
									onChange={e => changeValueVariableHandler(e, variable.id)}
								/>
							}
						</Form.Group>
					))}
					{!!requestStatus && <span className={'text-white d-block mt-2'}>{requestStatus}</span>}
					<div className={'d-flex justify-content-between'}>
						<Button
							className={'mt-2'}
							onClick={saveVariablesHandler}
						>
							Сохранить
						</Button>
						{variablesArray[0] === 'googleProxies' &&
							<Button
								className={'mt-2'}
								onClick={sendRatesHandler}
							>
								Отправить курсы в чаты
							</Button>
						}
					</div>
				</Form>
			}
		</>
	);
};

export default VariablesSettings;