import React from 'react';
import {Button, Card, Container, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

const CustomCard = ({message, messages, setMessages, setModalSettings, isVisible = false}) => {
    const calcTime = (ms) => {
        const localTime = new Date(ms).toLocaleString("ru-RU").split(', ');
        const time = localTime[1].split(':').slice(0, 2);
        const date = localTime[0].split('.');

        return <p>{+time[0]}:{time[1]}&nbsp;&nbsp;&nbsp;{+date[0]}/{+date[1]}/{+date[2]}</p>;
    }

    const copyId = async (id) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(id);
        } else {
            return document.execCommand('copy', true, id);
        }
    }
    //
    // const postMessageHandler = async () => {
    // 	await postMessage(message.id);
    // 	const newState = messages.map(obj => {
    // 		if (obj.id === message.id) {
    // 			return {...obj, isPosted: true};
    // 		}
    //
    // 		return obj;
    // 	});
    // 	setMessages(newState);
    // }

    const setPermBanHandler = async () => {
        setModalSettings({
            show: true,
            telegramUserId: message.telegramUserId,
            messageId: message.id,
            messages,
            setMessages,
            type: "ban"
        });
    }

    const deleteButtonHandler = () => {
        setModalSettings({show: true, messageId: message.id, messages, setMessages, type: "delete"});
    };

    return (
        <Card
            className={`text-center ${message["telegramUser"]["isSilverList"] && 'border-silver-card'} mt-2 ${isVisible && 'border-isExpand-card'}`}
            style={
                {
                    background: message["telegramUser"]["isBlackList"] ? "#9d787c" : "#002e4e",
                    color: "white"
                }}
        >
            <Card.Header className={"text-start d-flex justify-content-between align-items-center"}
                         style={{fontSize: "12px"}}>
                <div className={"d-flex align-items-center"}>
                    <div style={{
                        marginRight: "15px",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        backgroundImage: `url('${process.env.REACT_APP_API_URL}api/static/${message.imageSrc}')`,
                        backgroundSize: "cover",
                        flexShrink: 1
                    }}>

                    </div>
                    <div className={"d-flex flex-column align-items-start"} style={{flexShrink: 15}}>
                        <Nav.Link style={{color: "#b2c136"}} target={"_blank"}
                                  href={message?.urlChat || "#"}><b>{message?.titleChat?.slice(0, 30) || "Название отсутсвует"}</b></Nav.Link>
                        <div className={"d-flex"}>
                            <b>{message?.fromName || "Имя отсутсвует"}ᅠ|</b>
                            {message["telegramUser"]?.["userName"]
                                ?
                                <Nav.Link
                                    href={`https://t.me/${message["telegramUser"]["userName"]}`}
                                    target={"_blank"}
                                    style={{color: "rgb(255,129,91)"}}><b>ᅠ@{message["telegramUser"]["userName"]}</b></Nav.Link>
                                : null
                            }
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => copyId(message["telegramUser"]["userId"])}>
                            {message["telegramUser"]["userId"]}
                        </div>
                    </div>
                </div>
                <div style={{
                    marginRight: "15px",
                    height: "50px",
                    width: "60px",
                    borderRadius: "50%",
                    backgroundImage: `url('${process.env.REACT_APP_API_URL}api/static/${message["telegramUser"]["isBlackList"] ? "black" : "silver"}.png')`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    flexShrink: 1,
                    display: message["telegramUser"]["isBlackList"] || message["telegramUser"]["isSilverList"] ? "block" : "none"
                }}>
                </div>
                {/*<CloseButton variant={"white"} onClick={()=>deleteCard(message.id)}/>*/}
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {message.message}
                </Card.Text>
                <Container className={"d-flex justify-content-around"} style={{fontSize: "10px"}}>
                    <Nav.Link target={"_blank"}
                              href={message.link}>
                        <Button variant={"primary"} size={"sm"}
                                style={{backgroundColor: "#002e4e", borderColor: "#45486d"}}>
                            <b>Перейти</b>
                        </Button>
                    </Nav.Link>
                    {/*<Nav.Link onClick={postMessageHandler} href={""}>*/}
                    {/*	<span style={{opacity: message["telegramUser"]?.["userName"] ? 0 : 1, paddingRight: 5}}>🟡</span>*/}
                    {/*	<b>Опубликовать</b>*/}
                    {/*	<span style={{opacity: message.isPosted ? 1 : 0, paddingLeft: 5}}> ✅</span>*/}
                    {/*</Nav.Link>*/}
                    <Link to={`/messages/${message["telegramUser"]?.["userId"]}`} target={"_blank"}
                          style={{textDecoration: "none", color: "white"}}>
                        <Button variant={"primary"} size={"sm"}
                                style={{backgroundColor: "#002e4e", borderColor: "#45486d"}}>
                            <b>Найти ✉️ {message["messagesCount"]}</b>
                        </Button>
                    </Link>
                    <Button variant={"danger"} size={"sm"} style={{backgroundColor: "#002d4c", borderColor: "#dc3545"}}>
                        <b style={{cursor: "pointer"}} onClick={deleteButtonHandler}>🗑</b>
                    </Button>
                    <Button variant={"danger"} size={"sm"} style={{backgroundColor: "#002d4c", borderColor: "#dc3545"}}>
                        <b style={{cursor: "pointer"}} onClick={setPermBanHandler}>⛔️</b>
                    </Button>
                </Container>
            </Card.Body>
            <Card.Footer style={{color: "#23a8a9"}}>{calcTime(message.time)}</Card.Footer>

        </Card>
    );
};

export default CustomCard;